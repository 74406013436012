export const types = {
  HANDLE_CHANGE: "HANDLE_CHANGE",
  INIT_IMAGE: "INIT_IMAGE",
  INIT_RGB: "INIT_RGB",
  INIT_DEPTH: "INIT_DEPTH",
  TOGGLE_PAN: "TOGGLE_PAN",
  SELECT_TOOL: "SELECT_TOOL",
  STORE_SCRIBBLE_PARAMS: "STORE_SCRIBBLE_PARAMS",
  STORE_BOX_PARAMS: "STORE_BOX_PARAMS",
  STORE_SCALE_PARAMS: "STORE_SCALE_PARAMS",
  STORE_TOOL_PARAMETERS: "STORE_TOOL_PARAMETERS",
  STORE_PARAMETERS: "STORE_PARAMETERS",
  INIT_LAYER: "INIT_LAYER",
  ADD_LAYER: "ADD_LAYER",
  UPDATE_LAYER_INDEX: "UPDATE_LAYER_INDEX",
  UPDATE_LAYER: "UPDATE_LAYER",
  DUPLICATE_LAYER: "DUPLICATE_LAYER",
  REMOVE_LAYER: "REMOVE_LAYER",
  REMOVE_ALL_LAYER: "REMOVE_ALL_LAYER",
  TOGGLE_LAYER_SELECT: "TOGGLE_LAYER_SELECT",
  MERGE_LAYER_SELECT: "MERGE_LAYER_SELECT",
  REMOVE_LAYER_SELECT: "REMOVE_LAYER_SELECT",
  ADD_EFFECT: "ADD_EFFECT",
  ZOOM_IN: "ZOOM_IN",
  ZOOM_OUT: "ZOOM_OUT",
  UNDO: "UNDO",
  CLEAR: "CLEAR",
  RESET: "RESET",
  REMOVE_ITEM: "REMOVE_ITEM",
  REMOVE_ALL_ITEM: "REMOVE_ALL_ITEM"
};
